import React from 'react';
import dynamic from 'next/dynamic';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';

interface ICommonTypes {
  className?: string,
  dotsClass?: string,
  dots?: boolean,
  arrows?: boolean,
  infinite?: boolean,
  speed?: number,
  slidesPerRow?: number,
  rows?: number,
  initialSlide?: number,
  slidesToScroll?: number,
  autoplay?: boolean,
  centerMode?: boolean,
  centerPadding?: string,
  autoplaySpeed?: number,
  cssEase?: string,
  pauseOnHover?: boolean,
  swipeToSlide?: boolean,
  focusOnSelect?: boolean,
}

interface IResponsiveItem {
  breakpoint?: number,
  settings: ICommonTypes,
}

export interface ISlickProps extends ICommonTypes {
  responsive?: IResponsiveItem[],
  children: React.ReactNode,
}

const ReactSlick: React.ComponentType<ISlickProps> = dynamic(() => import('react-slick'), {
  loading: () => <Spinner overlay isSpinnerFixed />,
});

export { ReactSlick };
